@import "../helpers/mixins";
.ques{
    padding: 100px 0 20px 0;
    .container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include media(mopile){
          width: 90%;
                     };
        button{
          width: 200px;
          background-color: var(--other-color);
          border: none;
          padding: 5px;
          color: white;
          margin: auto;
          font-size: 25px;
          cursor: pointer;
        }
        .head{
            display: flex;
            width: 90%;
            border: 10px solid var(--other-color);
            border-radius: 10px;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            @include media(mopile){
              flex-direction: column
                         };
            img{
                width: 10%;
            }
            div{
                text-align: center;
                width: 60%;
                font-size: 30px;
                color: var(--my-color);
            }
        }
        .raters-emails{
            background-color: rgb(223, 230, 230);
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-radius: 10px;
            padding: 15px 0;
            text-align: center;
            button{
                background-color: var(--other-color);
                color: white;
                width: 20%;
                margin: auto;
                padding: 5px 0;
                border: none;
                border-radius: 5px;
                font-size: 25px;
                cursor: pointer;
                transition: 0.2s;
                &:hover{
                    background-color: var(--my-color);
                }
            }
            input{
                height: 20px;
                text-align: center;
                font-size: 15px;
            }
        }
        .all-ques{
            background-color: rgb(223, 230, 230);
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-radius: 10px;
            padding: 15px 0;
            .ques-card{
                background-color: white;
                width: 80%;
                margin: auto;
                padding: 5px 10px;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                gap: 10px;
               
                align-items: end;
      
                @include media(mopile){
                  width: 90%;    
                                  };
                .text{
             
                  display: flex; 
                  
                }

                .radio-input input {
                    display: none;
                  }
                  
                  .radio-input {
                    --container_width: 450px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    border-radius: 9999px;
                    background-color: #fff;
                    color: #000000;
                    width: var(--container_width);
                    overflow: hidden;
                    border: 1px solid rgba(53, 52, 52, 0.226);
                    @include media(mopile){
                      --container_width:300px;
                                 };
                  }
                  
                  .radio-input label {
                    
                    width: 100%;
                    padding: 10px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                    font-weight: 600;
                    letter-spacing: -1px;
                    font-size: 14px;
                    @include media(mopile){
                      font-size: 10px ;
                                 };
                    
                    
                  }
                  
                  .selection {
                    display: none;
                    position: absolute;
                    height: 100%;
                    width: calc(var(--container_width) / 5);
                    z-index: 0;
                    left: 0;
                    top: 0;
                    transition: .15s ease;
                  }
                  
                  .radio-input label:has(input:checked) {
                    color: #fff;
                  }
                  
                  .radio-input label:has(input:checked) ~ .selection {
                    background-color: rgb(11 117 223);
                    display: inline-block;
                  }
                  
                  .radio-input label:nth-child(1):has(input:checked) ~ .selection {
                    transform: translateX(calc(var(--container_width) * 0/5));
                  }
                  
                  .radio-input label:nth-child(2):has(input:checked) ~ .selection {
                    transform: translateX(calc(var(--container_width) * 1/5));
                  }
                  
                  .radio-input label:nth-child(3):has(input:checked) ~ .selection {
                    transform: translateX(calc(var(--container_width) * 2/5));
                  }
                  
                  .radio-input label:nth-child(4):has(input:checked) ~ .selection {
                    transform: translateX(calc(var(--container_width) * 3/5));
                  }
                  .radio-input label:nth-child(5):has(input:checked) ~ .selection {
                    transform: translateX(calc(var(--container_width) * 4/5));
                  }
            }
        }
    }
    .special.clicked{
      background-color: rgba(53, 52, 52, 0.226);
    }
    .known-answers{
      display: flex;
      justify-content: center;
      gap: 20px;
      padding: 1px;
     

flex-wrap: wrap;
      width: fit-content;
      align-items: center;
      .radio-button-container {
        display: flex;
        align-items: center;
        gap: 24px;
      }
      
      .radio-button {
        display: inline-block;
        position: relative;
        cursor: pointer;
      }
      
      .radio-button__input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .radio-button__label {
        display: inline-block;
        padding-left: 30px;
        margin-bottom: 10px;
        position: relative;
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        cursor: pointer;
        text-transform: uppercase;
        transition: all 0.3s ease;
      }
      
      .radio-button__custom {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #555;
        transition: all 0.3s ease;
      }
      
      .radio-button__input:checked + .radio-button__label .radio-button__custom {
        background-color: #4c8bf5;
        border-color: transparent;
        transform: scale(0.8);
        box-shadow: 0 0 20px #4c8bf580;
      }
      
      .radio-button__input:checked + .radio-button__label {
        color: #4c8bf5;
      }
      
      .radio-button__label:hover .radio-button__custom {
        transform: scale(1.2);
        border-color: #4c8bf5;
        box-shadow: 0 0 20px #4c8bf580;
      }
    }
}

.my-message{
  width: 100;
  min-height: 100vh;
  padding-bottom: 50px;

  
  .msg-cont{
    direction: rtl;
    margin: auto;
    background-color: var(--my-color);
    color: white;
    padding: 15px;
    border-radius: 15px;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .next{
    border: 2px solid white;
    margin: auto;
    padding: 8px 25px;
    border-radius: 10px;
    font-weight: 800;

  }
}