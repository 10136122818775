@mixin media ($type) {
    @if $type==mopile{
        @media (max-width:767px){
            @content
        }
    } @else if $type == large{
    @media (min-width:1200px){
        @content
    }
    
    } @else if $type == small{
    @media (min-width: 768px) and (max-width: 991px){
        @content
    }
    }
    @else if $type == med{
    @media  (min-width: 992px) and (max-width: 1199px){
        @content
    }
    }
}
