.contact {
  background-color: rgb(255, 255, 255);
  height: 100vh;
  width: 100%;
}
.contact .container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contact .container .first-info {
  display: flex;
  justify-content: space-evenly;
  border: 2px solid var(--my-color);
  border-radius: 15px;
  padding: 15px 0;
  align-items: center;
}
.contact .container .first-info .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.contact .container .first-info .card h3 {
  margin: 0;
}
.contact .container .first-info .card img {
  width: 40px;
}
.contact .container .first-info a img {
  width: 40px;
}
.contact h2 {
  text-align: right;
  margin-top: 140px;
  font-size: 30px;
  color: white;
  padding: 10px 20px;
  background-color: var(--my-color);
  border-radius: 10px;
}/*# sourceMappingURL=contact.css.map */