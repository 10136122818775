.landing {
  width: 100%;
  padding: 150px 0 10px 0;
  background-color: var(--other-color);
  margin-bottom: 30px;
  height: 75vh;
}
@media (max-width: 767px) {
  .landing {
    background-color: white;
  }
}
.landing .container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 80%;
  gap: 20px;
  z-index: 10;
}
@media (max-width: 767px) {
  .landing .container {
    flex-direction: column;
  }
}
.landing .img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing .img img {
  width: 300px;
}
.landing .login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
@media (max-width: 767px) {
  .landing .login {
    width: 100%;
  }
}
.landing .login form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 5px solid var(--my-color);
  padding: 40px 20px;
  border-radius: 10px;
  text-align: center;
  width: 70%;
}
.landing .login form label {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.landing .login form label input {
  text-align: center;
}
.landing .login form button {
  width: 40%;
  margin: auto;
  background-color: var(--other-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px;
}

.landing::before {
  content: "";
  position: absolute;
  left: 0;
  top: -30px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 0;
  transform: skewY(-6deg);
  transform-origin: top left;
}/*# sourceMappingURL=landing.css.map */