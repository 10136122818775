.ques {
  padding: 100px 0 20px 0;
}
.ques .container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 767px) {
  .ques .container {
    width: 90%;
  }
}
.ques .container button {
  width: 200px;
  background-color: var(--other-color);
  border: none;
  padding: 5px;
  color: white;
  margin: auto;
  font-size: 25px;
  cursor: pointer;
}
.ques .container .head {
  display: flex;
  width: 90%;
  border: 10px solid var(--other-color);
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
@media (max-width: 767px) {
  .ques .container .head {
    flex-direction: column;
  }
}
.ques .container .head img {
  width: 10%;
}
.ques .container .head div {
  text-align: center;
  width: 60%;
  font-size: 30px;
  color: var(--my-color);
}
.ques .container .raters-emails {
  background-color: rgb(223, 230, 230);
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 15px 0;
  text-align: center;
}
.ques .container .raters-emails button {
  background-color: var(--other-color);
  color: white;
  width: 20%;
  margin: auto;
  padding: 5px 0;
  border: none;
  border-radius: 5px;
  font-size: 25px;
  cursor: pointer;
  transition: 0.2s;
}
.ques .container .raters-emails button:hover {
  background-color: var(--my-color);
}
.ques .container .raters-emails input {
  height: 20px;
  text-align: center;
  font-size: 15px;
}
.ques .container .all-ques {
  background-color: rgb(223, 230, 230);
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 15px 0;
}
.ques .container .all-ques .ques-card {
  background-color: white;
  width: 80%;
  margin: auto;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;
}
@media (max-width: 767px) {
  .ques .container .all-ques .ques-card {
    width: 90%;
  }
}
.ques .container .all-ques .ques-card .text {
  display: flex;
}
.ques .container .all-ques .ques-card .radio-input input {
  display: none;
}
.ques .container .all-ques .ques-card .radio-input {
  --container_width: 450px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 9999px;
  background-color: #fff;
  color: #000000;
  width: var(--container_width);
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
}
@media (max-width: 767px) {
  .ques .container .all-ques .ques-card .radio-input {
    --container_width:300px;
  }
}
.ques .container .all-ques .ques-card .radio-input label {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-weight: 600;
  letter-spacing: -1px;
  font-size: 14px;
}
@media (max-width: 767px) {
  .ques .container .all-ques .ques-card .radio-input label {
    font-size: 10px;
  }
}
.ques .container .all-ques .ques-card .selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 5);
  z-index: 0;
  left: 0;
  top: 0;
  transition: 0.15s ease;
}
.ques .container .all-ques .ques-card .radio-input label:has(input:checked) {
  color: #fff;
}
.ques .container .all-ques .ques-card .radio-input label:has(input:checked) ~ .selection {
  background-color: rgb(11, 117, 223);
  display: inline-block;
}
.ques .container .all-ques .ques-card .radio-input label:nth-child(1):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 0 / 5));
}
.ques .container .all-ques .ques-card .radio-input label:nth-child(2):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 1 / 5));
}
.ques .container .all-ques .ques-card .radio-input label:nth-child(3):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 2 / 5));
}
.ques .container .all-ques .ques-card .radio-input label:nth-child(4):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 3 / 5));
}
.ques .container .all-ques .ques-card .radio-input label:nth-child(5):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 4 / 5));
}
.ques .special.clicked {
  background-color: rgba(53, 52, 52, 0.226);
}
.ques .known-answers {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 1px;
  flex-wrap: wrap;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
}
.ques .known-answers .radio-button-container {
  display: flex;
  align-items: center;
  gap: 24px;
}
.ques .known-answers .radio-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.ques .known-answers .radio-button__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.ques .known-answers .radio-button__label {
  display: inline-block;
  padding-left: 30px;
  margin-bottom: 10px;
  position: relative;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.ques .known-answers .radio-button__custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #555;
  transition: all 0.3s ease;
}
.ques .known-answers .radio-button__input:checked + .radio-button__label .radio-button__custom {
  background-color: #4c8bf5;
  border-color: transparent;
  transform: scale(0.8);
  box-shadow: 0 0 20px rgba(76, 139, 245, 0.5019607843);
}
.ques .known-answers .radio-button__input:checked + .radio-button__label {
  color: #4c8bf5;
}
.ques .known-answers .radio-button__label:hover .radio-button__custom {
  transform: scale(1.2);
  border-color: #4c8bf5;
  box-shadow: 0 0 20px rgba(76, 139, 245, 0.5019607843);
}

.my-message {
  width: 100;
  min-height: 100vh;
  padding-bottom: 50px;
}
.my-message .msg-cont {
  direction: rtl;
  margin: auto;
  background-color: var(--my-color);
  color: white;
  padding: 15px;
  border-radius: 15px;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.my-message .next {
  border: 2px solid white;
  margin: auto;
  padding: 8px 25px;
  border-radius: 10px;
  font-weight: 800;
}/*# sourceMappingURL=ques.css.map */