.home{

   
    .container{
        .ideas{
            padding: 30px;
    display: flex;
    justify-content: center;
    gap: 116px;
    img{
        
    border-radius: 15px;
    box-shadow: 5px 5px 12px;

    }
        }
    }
}