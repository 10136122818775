.raters {
  padding: 100px 0 20px 0;
}
.raters .container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.raters .container a {
  background-color: var(--other-color);
  width: 20%;
  padding: 5px;
  color: white;
  text-align: center;
  border-radius: 10px;
  margin: auto;
}
.raters .container label {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.raters .container label input {
  width: 60%;
}/*# sourceMappingURL=raters.css.map */