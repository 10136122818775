
  @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;1000&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;1000&family=Indie+Flower&family=Lobster&display=swap');
body {
  margin: 0;
  background: rgb(244, 245, 245);

  font-family: 'Cairo', sans-serif;
}
:root{
  --my-color:#28BBBC ;
  --other-color : #ed833e
}

