.who {
  padding: 100px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.who .head {
  display: flex;
  width: 90%;
  border: 10px solid var(--other-color);
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .who .head {
    flex-direction: column;
  }
}
.who .head img {
  width: 20%;
}
.who .head div {
  text-align: center;
  width: 60%;
  font-size: 30px;
  color: var(--my-color);
}
.who .who-im {
  width: 90%;
}
.who .container {
  text-align: center;
}/*# sourceMappingURL=who.css.map */