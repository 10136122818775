.App {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
}
.App .message{
  position: fixed;
  top: 40%;
  left: 40%;
  background-color: red;
  padding: 15px;
  z-index: 10000;
  color: white;
  font-size: 20px;
  border-radius: 10px;
}


.container{
  width: 90%;
  margin: auto;
}
::-webkit-scrollbar{
  height: 12px;
  width: 12px;
  }
  
  ::-webkit-scrollbar-track{
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-thumb{
  background: skyblue;
  border-radius: 1ex;
  }

  a{
    text-decoration: none;
    color: inherit
  }

  .login-error{
    position: fixed;
    z-index: 300;
    
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding: 40px;
    display: flex;
    flex-direction: column;
    color: white;
    background: #0000009e;
    border-radius: 11px;
    transition: 0.3s;
  }
  .login-error span {
    font-size: 30px;
  }
  .login-error a{
    text-align: center;
    margin-top: 34px;
    font-size: 21px;
    background: #ffffffba;
    color: black;
    padding: 10px;
    border-radius: 10px;
    transition: 0.2s;
  }
  .login-error a:hover{
    background-color: white;
  }
  .login-error div{
    font-size: 25px;
    cursor: pointer;
    transition: 0.3s;
  }
  .login-error div:hover{
    color: red;
  }

  

  .spin-cont{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 230;
    background-color: #ffffffb3;
    }
    /* From Uiverse.io by SchawnnahJ */ 
.loader {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
 }
 
 .loader:before, .loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
 }
 
 .loader:before {
  animation: before8 2s infinite;
 }
 
 .loader:after {
  animation: after6 2s infinite;
 }
 
 @keyframes before8 {
  0% {
   width: 0.5em;
   box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
  }
 
  35% {
   width: 2.5em;
   box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
  }
 
  70% {
   width: 0.5em;
   box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
  }
 
  100% {
   box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
  }
 }
 
 @keyframes after6 {
  0% {
   height: 0.5em;
   box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
  }
 
  35% {
   height: 2.5em;
   box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
  }
 
  70% {
   height: 0.5em;
   box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
  }
 
  100% {
   box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
  }
 }
 
 .loader {
  position: absolute;
  top: calc(50% - 1.25em);
  left: calc(50% - 1.25em);
 }
    .spinner {
        /* // margin: 100px auto; */
        
        
        text-align: center;
        font-size: 10px;
        /* // background-color: white; */
        position: absolute;
        z-index: 260;
        width: 200px;
        height: 100px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        
      }
      
      .spinner > div {
        background-color: #333;
        height: 100%;
        width: 20px;
        display: inline-block;
        
        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
      }
      
      .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }
      
      .spinner .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
      }
      
      .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }
      
      .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }
      
      @-webkit-keyframes sk-stretchdelay {
        0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
        20% { -webkit-transform: scaleY(1.0) }
      }
      
      @keyframes sk-stretchdelay {
        0%, 40%, 100% { 
          transform: scaleY(0.4);
          -webkit-transform: scaleY(0.4);
        }  20% { 
          transform: scaleY(1.0);
          -webkit-transform: scaleY(1.0);
        }
      }


      .suc ,.request-done ,.paid{
        position: fixed;
        background-color: green;
        color: white;
        padding: 25px;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border: 4px solid white;
        border-radius: 10px;
        font-size: 25px;
      }
      .fail ,.no-balance{
        position: fixed;
        background-color: rgb(211, 15, 15);
        color: white;
        padding: 25px;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border: 4px solid white;
        border-radius: 10px;
        font-size: 25px;
      }
      .whats{
        position: fixed; 
        width: 50px;
        right: 20px;
        top: 50px;
        z-index: 10000;
      } 
      .whats img{
        width: 100%;
      }