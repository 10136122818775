.mmm {
  color: blue;
  background-color: black;
}

.profile {
  padding: 100px 0 20px 0;
}
.profile .recharts-surface {
  width: 70% !important;
}
@media (max-width: 767px) {
  .profile .recharts-surface {
    width: 90% !important;
  }
}
@media (max-width: 767px) {
  .profile .recharts-legend-item-text {
    font-size: 10px !important;
  }
}
@media (max-width: 767px) {
  .profile tspan {
    font-size: 10px !important;
  }
}
.profile .graph .grapgh-title {
  margin-top: 30px;
  font-size: 15px;
  background-color: var(--other-color);
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: auto;
  margin-top: 60px;
  color: white;
  padding: 10px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .profile .graph .grapgh-title {
    font-size: 8px;
  }
}
.profile #element1, .profile #element2 {
  width: 100%;
  margin-top: 80px;
  height: 400px;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .profile #element1, .profile #element2 {
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 5px 0;
  }
}
.profile .full-report {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  overflow: scroll;
}
.profile .full-report .over {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.664);
}
.profile .full-report button {
  background-color: var(--my-color);
  color: white;
  font-size: 20px;
  padding: 5px 15px;
  border-radius: 5px;
  transition: 0.2s;
  cursor: pointer;
}
.profile .full-report button:hover {
  background-color: black;
}
.profile .full-report .in-full {
  width: 85%;
  padding: 15px;
  background-color: white;
  margin: auto;
  border: 3px solid var(--other-color);
  border-radius: 10px;
  margin-top: 130px;
  position: relative;
  z-index: 2200;
}
@media (max-width: 767px) {
  .profile .full-report .in-full {
    width: 93%;
  }
}
.profile .full-report table, .profile .full-report th, .profile .full-report td {
  border: 1px solid black;
}
.profile .full-report th {
  color: var(--my-color);
  padding: 4px;
}
.profile .full-report .table {
  width: 80%;
  margin: 15px auto;
  padding: 0 0 15px 0;
  text-align: center;
  border: 2px solid var(--other-color);
  border-radius: 10px;
}
@media (max-width: 767px) {
  .profile .full-report .table {
    width: 95%;
    padding: 5px;
    overflow-x: scroll;
  }
}
.profile .full-report .table table {
  margin: auto;
}
.profile .full-report .table .small {
  width: 50%;
}
.profile .report {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.534);
  z-index: 2000;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  overflow: scroll;
  padding-bottom: 20px;
}
.profile .report button {
  background-color: var(--my-color);
  padding: 10px 15px;
  border: 2px solid white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.profile .report h2 {
  color: white;
}
.profile .report .in-report {
  background-color: white;
  width: 70%;
  margin: 0 auto;
  padding: 15px 10px;
  border-radius: 10px;
  border: 4px solid var(--other-color);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 767px) {
  .profile .report .in-report {
    width: 90%;
    overflow-x: scroll;
  }
}
.profile .report .in-report img {
  width: 150px;
}
@media (max-width: 767px) {
  .profile .report .in-report img {
    width: 100px;
  }
}
.profile .report .in-report .user-data {
  display: flex;
  width: 90%;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}
.profile .report .in-report .user-data div {
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 10px;
  text-transform: capitalize;
  font-weight: bold;
}
@media (max-width: 767px) {
  .profile .report .in-report .user-data div {
    font-size: 12px;
  }
}
.profile .report .in-report table, .profile .report .in-report th, .profile .report .in-report td {
  border: 2px solid black;
}
.profile .report .in-report th {
  color: var(--my-color);
}
.profile .results {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.534);
  z-index: 2000;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  overflow: scroll;
  padding-bottom: 20px;
}
.profile .results button {
  background-color: var(--my-color);
  padding: 10px 15px;
  border: 2px solid white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.profile .results h2 {
  color: white;
}
.profile .results .in-results {
  background-color: white;
  width: 70%;
  margin: 0 auto;
  border-radius: 10px;
  border: 4px solid var(--other-color);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 10px;
}
@media (max-width: 767px) {
  .profile .results .in-results {
    width: 90%;
    overflow-x: scroll;
  }
}
.profile .results .in-results table, .profile .results .in-results th, .profile .results .in-results td {
  border: 2px solid;
}
.profile .results .in-results img {
  width: 150px;
}
@media (max-width: 767px) {
  .profile .results .in-results img {
    width: 90px;
  }
}
.profile .results .in-results .user-data {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  font-size: 12px;
}
.profile .results .in-results .user-data div {
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 10px;
  text-transform: capitalize;
  font-weight: bold;
}
.profile .container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.profile .container .data {
  width: 100%;
  background-color: var(--my-color);
  padding: 15px 0;
  border-radius: 10px;
  color: white;
}
.profile .container .quiz-data {
  background-color: var(--other-color);
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 15px 0;
  border-radius: 10px;
  width: 100%;
}
.profile .container .quiz-data div {
  background-color: white;
  padding: 15x;
  width: 15%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
}
.profile .container .quiz-data div:hover {
  background-color: var(--other-color);
  color: rgb(255, 255, 255);
}
.profile .container .keys {
  background-color: var(--my-color);
  padding: 15px 0;
  border-radius: 10px;
  width: 100%;
  color: white;
}
.profile .container .keys .in-keys {
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}
.profile .container .keys .in-keys .key-card {
  border: 2px solid white;
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.2s;
  margin-bottom: 15px;
}
.profile .container .keys .in-keys .key-card:hover {
  background-color: var(--my-color);
  color: white;
}
.profile .right {
  background-color: green;
  color: white;
}
.profile .wrong {
  background-color: red;
  color: white;
}
.profile .scale {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.profile .scale span {
  border: 1px solid;
  padding: 3px 10px;
  border-radius: 5px;
}/*# sourceMappingURL=profile.css.map */