@import "../helpers/mixins";
.mmm{
    color: blue;
    background-color: black;
}
.profile{
    padding: 100px 0 20px 0;
 
    .recharts-surface{
        width: 70% !important;
        @include media(mopile){
            width: 90% !important ;
     
          
 };
    }
    .recharts-legend-item-text{
        @include media(mopile){
           font-size: 10px !important ;}
    }
    tspan{
        @include media(mopile){
            font-size: 10px !important ;}
    }
.graph{
    .grapgh-title{
        margin-top: 30px;
        font-size: 15px;
        background-color: var(--other-color);
        max-width: fit-content;
        margin: auto;
        margin-top: 60px;
        color: white;
        padding: 10px;
        border-radius: 10px;
        @include media(mopile){
            font-size: 8px
 };
    }
}
     #element1,#element2{
        width: 100%;
        margin-top: 80px;
        height: 400px;
        display: flex;
        justify-content: center;
        @include media(mopile){
            display: flex;
            justify-content: center;
            overflow: hidden ;
         
            padding: 5px 0

        };
    }
    .full-report{
        position: fixed;
        width: 100%;
       
        top: 0;
        left: 0;
        height: 100%;
     
      
    overflow: scroll;
    .over{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        background-color: rgba(0, 0, 0, 0.664);
    }
       button{
        background-color: var(--my-color);
        color: white;
        font-size: 20px;
        padding: 5px 15px;
        border-radius: 5px;
        transition: 0.2s ;

        &:hover{
            background-color: black;
        }
        cursor: pointer;
       }
        .in-full{
width: 85%;
padding: 15px;
background-color: white;
margin: auto;
border: 3px solid var(--other-color);
border-radius: 10px;
margin-top: 130px;
position: relative;
z-index: 2200;
@include media(mopile){
    width: 93%;

               };

        }
        table , th, td{
            border: 1px solid black;
        }
        th{
        color: var(--my-color);
        padding: 4px;

        }
        .table{
            width: 80%;
            margin: 15px auto;
            padding: 0 0 15px 0;
            text-align: center;
            border: 2px solid var(--other-color);
            border-radius: 10px;
            @include media(mopile){
                width: 95%;
                padding: 5px;
                overflow-x: scroll
                           };
            table{
                margin: auto;
            }
            .small{
                width: 50%;
            }
        }
        
    }
    .report{
        position: fixed;
        background-color: rgba(0, 0, 0, 0.534);
        z-index: 2000;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
        overflow: scroll;
        padding-bottom: 20px;
        button{
            background-color: var(--my-color);
            padding: 10px 15px;
            border: 2px solid white;
            font-weight: bold;
            font-size: 20px;
            border-radius: 5px;
            cursor: pointer;
        }
        h2{
            color: white;
        }
        .in-report{
            background-color: white;
            width: 70%;
            margin: 0 auto;
            padding: 15px 10px;
            border-radius: 10px;
            border: 4px solid var(--other-color);
            display: flex;
            flex-direction: column;
            gap: 10px;
            @include media(mopile){
                width: 90%;
                overflow-x: scroll
                           };
            img{
                width: 150px;
                @include media(mopile){
                    width: 100px;
                               };
            }
            .user-data{
                display: flex;
                width: 90%;
                justify-content: center;
                gap: 15px;
                flex-wrap: wrap;
              
                div{
                    border: 1px solid;
                    padding: 5px 10px;
                    border-radius: 10px;
                    text-transform: capitalize;
                    font-weight: bold;
                    @include media(mopile){
                        font-size: 12px
                                   };
                }
            }
            table , th, td{
                border: 2px solid black;
            }
            th{
            color: var(--my-color);
   
            }
       
          
       
        
    }
    }
    .results{
        position: fixed;
        background-color: rgba(0, 0, 0, 0.534);
        z-index: 2000;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
        overflow: scroll;
        padding-bottom: 20px;
        button{
            background-color: var(--my-color);
            padding: 10px 15px;
            border: 2px solid white;
            font-weight: bold;
            font-size: 20px;
            border-radius: 5px;
            cursor: pointer;
        }
        h2{
            color: white;
        }
        .in-results{
            background-color: white;
            width: 70%;
            margin: 0 auto;
            border-radius: 10px;
            border: 4px solid var(--other-color);
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 15px 10px;
            @include media(mopile){
                width: 90%;
                overflow-x: scroll
                           };
            table , th, td{
                border: 2px solid;
            }
            img{
                width: 150px;
                @include media(mopile){
                    width: 90px;
                               };
            }
            .user-data{
                display: flex;
                width: 100%;
                justify-content: center;
                gap: 15px;
                flex-wrap: wrap;
                font-size: 12px;
                div{
                    border: 1px solid;
                    padding: 5px 10px;
                    border-radius: 10px;
                    text-transform: capitalize;
                    font-weight: bold;
                }
            }
      
        }
    }
    .container{
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .data{
            width: 100%;
            background-color: var(--my-color);
            padding: 15px 0;
            border-radius: 10px;
            color: white;
        }
        .quiz-data{
            background-color: var(--other-color);
            display: flex;
            justify-content: center;
            gap: 40px;
            padding: 15px 0;
            border-radius: 10px;
            width: 100%;
            div{
                background-color: white;
                padding: 15x;
                width: 15%;
                text-align: center;
                padding: 10px;
                border-radius: 5px;
                cursor: pointer;
                transition: 0.2s;
                &:hover{
                    background-color: var(--other-color);
                    color: rgb(255, 255, 255);
                }

            }
        }
        .keys{
            background-color: var(--my-color);
            padding: 15px 0;
            border-radius: 10px;
            width: 100%;
            color: white;
            .in-keys{
                display: flex;
                width: 80%;
                margin: auto;
                justify-content: center;
                flex-wrap: wrap;
                gap: 15px;
                .key-card{
                    border: 2px solid white;
                    padding: 5px;
                    border-radius: 5px;
                    background-color: white;
                    color: black;
                    cursor: pointer;
                    padding: 5px 10px;
                    border-radius: 5px;
                    transition: 0.2s;
                    margin-bottom: 15px;

                 
                
                    &:hover{
                        background-color: var(--my-color);
                        color: white;
                    }
                }
            }
        }
    }
    .right{
        background-color: green;
        color: white;
    }
    .wrong{
        background-color: red;
        color: white;
    }
    .scale{
        display: flex;
        gap: 10px;
        justify-content: center;
        span{
            border: 1px solid;
            padding: 3px 10px;
            border-radius: 5px;
        }
    }
}