.raters{
    padding: 100px 0 20px 0;
    .container{
        display: flex;
        flex-direction: column;
        gap: 15px;
        a{
            background-color: var(--other-color);
            width: 20%;
            padding: 5px;
            color: white;
            text-align: center;
            border-radius: 10px;
            margin: auto;
        }
        label{
            width: 60%;
            display: flex;
            justify-content: space-between;
            margin: auto;
            input{
                width: 60%;

            }
        }
    }
}